import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

 
 
 
export default function MainSportList() {

  const jsonUrl = variables.basename_url +'/updates/index/Coinsinsight_HomeEn_BusFinance.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
               console.log(response.data);
           
              
         
  
             for (let i = 0; i <= 0 && i < response.data.length; i++) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date.split(" ")[0],
                image: response.data[i].image  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>
 

               
     
         
      
      
        {JsonArray.map(da=>     
        <div className="post-sports-item">
          <div className="post-sports-thumb">
            <a href={da.link}>
            <img    className='sport-big-Img rounded' src={da.image} alt="" />
            </a>
          </div>
          <div className="post-sports-content">
            <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
            <p className="text">{parse(da.Description)}</p>
           
          </div>
        </div>

              
  
   
       )}
 
 
         </>
        );
    }


  
