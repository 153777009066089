import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import MainSportList from './MainSportList.js';
 
 
export default function SportList() {

  const jsonUrl = variables.basename_url +'/updates/index/Coinsinsight_HomeEn_BusFinance.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                  
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                  
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
            
              
             
  
             for (let i = 1; i <= 4 && i < response.data.length; i++) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date.split(" ")[0],
                image: response.data[i].image  ,

                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>
 

               
      <div className="post-sports-box mt-40">
        <div className="section-title">
          <h3 className="title">Business & Finance</h3>
        </div>
         <MainSportList/>

              
        {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
        
      <div className="post_gallery_items">
        <div className="post-gallery-style-2">
          <div className="post-gallery-thumb">
            <a href={da.link}>
            <img   className='sport-small-img rounded ' src={da.image} alt="gallery" />
            </a>
          </div>
          <div className="post-gallery-content">
            <h5 className="title"><a href={da.link}>{da.news_title}</a></h5>
             
          </div>
        </div>
        <div></div> 
       
        
      
      </div>




       
      </div>
       )}
 </div>
 
         </>
        );
    }


  
