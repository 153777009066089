import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

 
 
 
export default function TrendingList2() {

  const jsonUrl = variables.basename_url +'updates/index/Coinsinsight_HomeEn_TrendingNews.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
           
              
         
  
             for (let i = 6; i <= 8 && i < response.data.length; i++) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date.split(" ")[0],
                image: response.data[i].image  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>
 
              
      
         
       

         
 
           
            {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
              <div className="gallery_item p-3">
                <div className="gallery_item_thumb">
                  <a href={da.link}>
                <img className='Trending-list-Img rounded'   src={da.image} alt="gallery" />
                </a>
                  <div className="icon"></div>
                </div>
                <div className="gallery_item_content ml-3">
                  <div className="post-meta">
                    <div className="meta-categories">
                      <a >{da.Category}</a>
                    </div>
                    <div className="meta-date">
                      <span>{da.news_date}</span>
                    </div>
                  </div>
                  <h4 className="title"><a href={da.link}>{da.news_title}</a></h4>
                </div>
              </div>
            </div>
              )}
              
      
      
 
         </>
        );
    }


  
