import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import TrendingList from './TrendingList.js';
import TrendingList2 from './TrendingList2.js';

 
export default function TrendingNewsCoins() {

  const jsonUrl = variables.basename_url +'updates/index/Coinsinsight_HomeEn_TrendingNews.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
      Get_Data();
     
    }

      const [JsonArray, setJsonArray] = React.useState([]);
 


      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
  
       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
            
              
         
  
             for (let i = 0; i <= 1 && i < response.data.length; i++) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date.split(" ")[0],
                image:response.data[i].image  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
      
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )











 










            return (
          <>
 
              
     
        <div className="section-title">
          <h3 className="title">Trending News</h3>
        </div>
        <div className="row trending-news-slider slick-initialized slick-slider"> 
          <div className="slick-list draggable">
            <div className=" slick-track col     container "  >
            
               
              
             
              {/* Start from here  */}
              {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 

              <div className="col-lg-6 col-lg-6 col-md-6 col-sm-12  slick-slide slick-cloned   " data-slick-index={4} aria-hidden="true" tabIndex={-1} style={{width: '380px'}}>
                <div className="trending-news-item">
                  <div className="trending-news-thumb">
                 <a href={da.link}>
                    <img className='Trending-Img'   src={da.image} alt={da.news_title} />
                    </a>
                  </div>
                  <div className="trending-news-content">
                    <div className="post-meta">
                      <div className="meta-categories">
                        <a  tabIndex={-1}>{da.Category}</a>
                      </div>
                      <div className="meta-date">
                        <span>{da.news_date}</span>
                      </div>
                    </div>
                    <h3 className="title"><a href={da.link} tabIndex={-1}>{da.news_title}</a></h3>
                    <p className="text">{parse(da.Description)}</p>
                  </div>
                </div>
              </div>

              </div>
                 )}
               {/* End from here  */}

 
              

            
              </div></div>
          </div>
        <div className="row">

         
        <div className="col-lg-6 col-md-6">
            <div className="trending-news-post-items">
           
       <TrendingList/>
              </div>
             
      
          </div>
         

          <div className="col-lg-6 col-md-6">
            <div className="trending-news-post-items">
           
       <TrendingList2/>
              </div>
             
      
          </div>
        </div>
 
         </>
        );
    }


  
