import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import MostViewed from './MostViwed.js';


export default function Reports() {

  const jsonUrl = variables.basename_url +'/updates/index/Coinsinsight_HomeEn_Reports.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
	 



    fetch( variables.API_URL+ '/external_site/meta_Domain?Domain=' + variables.domain + '&FullDomain=' + variables.basename_url   ,{
        method:'GET',
        headers:{'content-type':'application/json'},
        
    }).then((res) => {
        return res.json();
    }).then((resp) => {
        console.log(resp);
        
        
        
       
    }).catch((err) => {
        // alert('  Failed  ');
    });


      Get_Data();
     
    }

    
      const [JsonArray, setJsonArray] = React.useState([]);
      const [JsonArray2, setJsonArray2] = React.useState([]);


      async function Get_Data(){
     
         //to clear All data in your page
        

       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);
  
               console.log(response.data);
           
              
         
  
               for (let i = 1; i <= response.data.length && i <= response.data.length; i++) {
                let j = i ++;
                // Ensure j does not exceed the length of response.data
                if (j <= response.data.length) {
                    // Perform your operations here
                    JsonArray.push({
                        news_title: response.data[i - 1].news_title,
                        news_date: response.data[i - 1].news_date.split(" ")[0],
                        image: response.data[i-1].image  ,
                        link: response.data[i - 1].link,
                        Description: response.data[i - 1].Description.replace("https://menafn.com/", variables.domain_name + "/")
                            .replace("https://menafn.com", variables.domain_name)
                            .replace("MENAFN", variables.domain)
                            .replace("<table>","")
                            .replace("<p>","")
                           .replace("</br>","")
                           .replace("<b>","")
                           .replace("<p></p><p></p>","")
                           .replace("<br />","")
                           .replace("</p>","")
                           .replace("<strong>","")
                           .replace("</strong>","")
                            .replace("MENAFN", variables.domain),
                        Category: response.data[i - 1].Category,
                    });
            
                    JsonArray2.push({
                        news_title: response.data[j - 1].news_title,
                        news_date: response.data[j - 1].news_date.split(" ")[0],
                        image: response.data[j-1].image  ,
                        link: response.data[j - 1].link,
                        Description: response.data[j - 1].Description.replace("https://menafn.com/", variables.domain_name + "/")
                            .replace("https://menafn.com", variables.domain_name)
                            .replace("MENAFN", variables.domain)
                            .replace("<table>","")
                             .replace("<p>","")
                            .replace("</br>","")
                            .replace("<b>","")
                            .replace("</p>","")
                            .replace("<p></p><p></p>","")
                            .replace("<br />","")
                            .replace("<strong>","")
                            .replace("</strong>","")
                            .replace("MENAFN", variables.domain),
                        Category: response.data[j - 1].Category,
                    });
                }
            }
            

        })      
       }
         
    
     
       
   
       
         
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


            return (
          <>

 
 
 
<section className="all-post-style-2-area mt-3">
        <div className="container custom-container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="post-entertainment">
                
              <ol className="breadcrumb" style={{background:"whitesmoke",height:"28px",marginBottom:"22px"}}>
                <h4 className="breadcrumb-item "><a href="Home">Home</a></h4>
                
                <h4 className="title breadcrumb-item">
                  <a href="MarketResearch">MarketResearch News</a>
                  </h4></ol>
            
 
                <div className="row">
   
            
               
 
                  <div className="col-lg-6 col-md-6 p-3">
                  {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
                    <div className="trending-news-item mb-30">
                      <div className="trending-news-thumb">
                        <a href={da.link}>
                        <img className=' Sections-Img  rounded'    src={da.image} alt="trending" />
                        </a>
                        <div className="circle-bar">
                          <div className="first circle"><canvas width={40} height={40} />
                            
                          </div>
                        </div>
                      </div>
                      <div className="trending-news-content">
                        <div className="post-meta">
                          <div className="meta-categories">
                            <a >{da.Category}</a>
                          </div>
                          <div className="meta-date">
                            <span>{da.news_date}</span>
                          </div>
                        </div>
                        <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
                        <p className="text">{da.Description}</p>
                      </div>
                    </div>
                    </div>
  )}
                  </div>

                  <div className="col-lg-6 col-md-6 p-3">
                  {JsonArray2.map(da=>     
  
  <div key= {da.news_title}  > 
                    <div className="trending-news-item mb-30">
                      <div className="trending-news-thumb">
                        <a href={da.link}>
                        <img className='   rounded'    src={da.image} alt="trending" />
                        </a>
                        <div className="circle-bar">
                          <div className="first circle"><canvas width={40} height={40} />
                            
                          </div>
                        </div>
                      </div>
                      <div className="trending-news-content">
                        <div className="post-meta">
                          <div className="meta-categories">
                            <a >{da.Category}</a>
                          </div>
                          <div className="meta-date">
                            <span>{da.news_date}</span>
                          </div>
                        </div>
                        <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
                        <p className="text">{parse(da.Description)}</p>
                      </div>
                    </div>
                    </div>
  )}
                  </div>


                  
   


                </div>
                
              </div>
             
              <div className="post-add mt-30">
                <a href="#"><img src="./images/ad/ad-1.png" alt="ad" /></a>
              </div>
            
            </div>
            <div className="col-lg-4 mb-auto">
              <div className="all-post-sidebar">
                <div className="most-share-post">
                  <div className="section-title mt-2">
                    <h3 className="title ">Most View</h3>
                  </div>
                </div>
              <MostViewed/>


            
           
               
              </div>
            </div>
          </div>
        </div>
      </section>
         </>
        );
    }


  
