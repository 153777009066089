import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function MostViewed() {

  const jsonUrl = variables.basename_url +'updates/index/Coinsinsight_HomeEn_MostViewed.json';

 


    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
  
  
      async function refda() {  
 
        
        Get_Data_MostViewed();
      };

   
 
    
      const [JsonArray, setJsonArray] = React.useState([]);
       


           
   
   async function Get_Data_MostViewed(){
 
    JsonArray.splice(0);  //to clear All data in your page

   setNisLoading(true);

   axios.get(jsonUrl)  

         .then(function (response) {

           
        
          setNisLoading( false);


        
          
     

         for (let i = 0; i <= 4 && i < response.data.length; i++) {
         
          JsonArray.push({
        
            news_title: response.data[i].news_title,
            news_date:response.data[i].news_date.split(" ")[0],
            Image: response.data[i].Image  ,

            link:response.data[i].link,
            Category:response.data[i].Category,
            Description:response.data[i].Description,
          })
          }
    })      
   }
     

   console.log(JsonArray);
       
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


          
         
 

           
 
  




            return (
          <>


<div className="trending-sidebar-slider slick-initialized slick-slider">
                  <div className="slick-list draggable">
                    <div className="slick-track" style={{opacity: 1, width: '1750px', transform: 'translate3d(-1050px, 0px, 0px)',  }}>
                      <div className="post_gallery_items slick-slide slick-cloned" data-slick-index={-1} aria-hidden="true" tabIndex={-1} style={{width: '350px'}}>
                        
                     
              
                  
                     
                      
                      </div>
                      
                      
                      <div className="post_gallery_items slick-slide slick-current slick-active" data-slick-index={0} aria-hidden="false" tabIndex={-1} style={{width: '350px'}}>
                      
                     
                
                   
                         
                        <div className="gallery_item gallery_item-style-2">
                          <div className="gallery_item_thumb">
                            {/* <img src="assets/images/most-post/most-6.jpg" alt="gallery" /> */}
                          </div>
                          <div className="gallery_item_content">
                            <div className="post-meta">
                              <div className="meta-categories">
                                <a href="#" tabIndex={-1}>TECHNOLOGY</a>
                              </div>
                              <div className="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 className="title"><a href="#" tabIndex={-1}>Ratiffe to be Director of nation talent Trump ignored</a></h4>
                            <span>6</span>
                          </div>
                        </div>
                      </div>
                      
                      
                      <div className="post_gallery_items slick-slide" data-slick-index={1} aria-hidden="true" tabIndex={0} style={{width: '350px'}}>
                  
                      
                       
                
                      
                
                        <div className="gallery_item gallery_item-style-2">
                          <div className="gallery_item_thumb">
                            {/* <img src="assets/images/most-post/most-6.jpg" alt="gallery" /> */}
                          </div>
                          <div className="gallery_item_content">
                            <div className="post-meta">
                              <div className="meta-categories">
                                <a href="#" tabIndex={0}>TECHNOLOGY</a>
                              </div>
                              <div className="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 className="title"><a href="#" tabIndex={0}>Ratiffe to be Director of nation talent Trump ignored</a></h4>
                            <span>6</span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="post_gallery_items slick-slide slick-cloned" data-slick-index={2} aria-hidden="true" tabIndex={-1} style={{width: '350px'}}>
                  
                    
                      {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
                    
                        <div className="gallery_item gallery_item-style-2  ">
                          <div className="gallery_item_thumb">
                            <a href={da.link}>
                            <img     src={da.Image}  className='MostView-Img' alt="gallery" />
                            </a>
                          </div>
                          <div className="gallery_item_content    ml-3">
                            <div className="post-meta">
                              <div className="meta-categories">
                                <a  tabIndex={-1}>{da.Category}</a>
                              </div>
                              <div className="meta-date">
                                <span>{da.news_date}</span>
                              </div>
                            </div>
                            <h4 className="title "><a className='' href={da.link} tabIndex={-1}>{da.news_title}</a></h4>
                            
                          </div>
                        </div>
                        </div>
                          )}
                      </div>
                           
                      
                      <div className="post_gallery_items slick-slide slick-cloned" data-slick-index={3} aria-hidden="true" tabIndex={-1} style={{width: '350px'}}>
                     
                   
             
                
                
                    

                      </div>
                      </div>
                      </div>
                   </div>

 
         </>
        );
    }


  
