 


// Ibs
 
// export const variables={
           
//   API_URL:"http://199.0.0.98:8092/mgmt_react/API/api" 

// ,basename: '' 

// }


  

  
// menafn LocalHost   
// export const variables={


//   API_URL:"http://localhost:6605/api" 
  

// ,ImageStart: "https://menafn.com/updates/pr/"
     
//              ,basename: '' 
//             ,basename_url: 'https://menafn.com/' 
//             ,domain_name: 'http://menafn.com'
//              ,basename_url_http: 'http://coinsinsight.com/' 

//             ,domain: 'menafn'         
//   }


  // coinsinsight LocalHost   
// export const variables={


//   API_URL:"http://localhost:6605/api" 
  

// ,ImageStart: "https://coinsinsight.com/updates/pr/"
     
//              ,basename: '' 
//             ,basename_url: 'https://coinsinsight.com/' 
//             ,domain_name: 'https://coinsinsight.com'
//             ,domain: 'CoinsInsight'         
//   }

// //Online   
export const variables={


API_URL:"https://coinsinsight.com/mgmt_react/API/api" 
,ImageStart: "https://coinsinsight.com/updates/pr/"

               ,basename: '' 
               ,basename_url: 'https://coinsinsight.com/' ,
               basename_url_http: 'http://coinsinsight.com/' 
               ,domain_name: 'https://coinsinsight.com'
               ,domain: 'coinsinsight'

       
  }

 
  
