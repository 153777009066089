import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import { Helmet } from "react-helmet"
 
export default function NewFeature() {
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();

    const jsonUrlBoxing = variables.basename_url +'updates/index/Coinsinsight_HomeEn_Featured.json';      
        
      async function refda() {  
        setNisLoading(true);
        Get_Data_Boxing();
       
      }
   
   
      useEffect(() => {
      
        setNisLoading(true);
   
        refda();
           },[] )

           const [JsonArray, setJsonArray] = React.useState([]);

           async function Get_Data_Boxing(){
          
            JsonArray.splice(0);  //to clear All data in your page
         
            setNisLoading(true);
         
            axios.get(jsonUrlBoxing)  
         
                  .then(function (response) {
                  setNisLoading( false);
         
                                          
         
                  for (let i = 0; i <= 3 && i < response.data.length; i++) {
                  
                    JsonArray.push({
                     news_title: response.data[i].news_title,
                     news_date:response.data[i].news_date.split(" ")[0],
                     image: response.data[i].image  ,

                     link:response.data[i].link,
                     Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,
                     Category:response.data[i].Category
        
                   })
                   }
             })      
            }
               
            return (
          <>
       
       
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h3 className="title">Feature News</h3>
              </div>
            </div>
          </div>
          
          <div className="row   slick-initialized slick-slider">
             <div className="slick-list draggable">
              <div className="Features-Img"    >
               
               
                
                
              
               
              
             
                
             
              <div className=' '>
              {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
 
                 <div className="p-2  slick-slide slick-cloned  col-lg-3 col-md-3 col-sm-12 mt-2 "     >
                <div className="feature-post">
                    <div className=" feature-post-thumb">
                    <a href={da.link}>
                      <img className='rounded   Feature-Img' src={da.image} alt="feature" />
                      </a>
                    </div>
                    <div className="feature-post-content">
                      <div className="post-meta">
                        <div className="meta-categories">
                          <a  tabIndex={-1}>{da.Category}</a>
                        </div>
                        <div className="meta-date">
                          <span>{da.news_date}</span>
                        </div>
                      </div>
                      <h4 className="title"><a href={da.link} tabIndex={-1}>{da.news_title}</a></h4>
                    </div>
                  </div>
                </div>
                </div>
          

                

)}
      </div>


                </div></div>
             </div>
        </div>
 
  
 
         </>
        );
    }


  
